'use strict';

angular.module('mvpcarrefourApp')
    .directive('navbar', ($window, $state,$rootScope) => ({
        templateUrl: 'components/navbar/navbar.html',
        restrict: 'E',
        link: function(scope, elem, attrs) {

            // Se checkea al entrar 1 vez
            checkStateAndwidth($window, scope);

            //Se checkea en los posibles cambios del width ventana
            $(window).resize(function() {

                scope.$apply(function() {

                    checkStateAndwidth($window, scope);
                });

            });

            //Enlace para compartir voto y participar en el sorteo visible para usuarios que han completado la votacion
            scope.showShareVote = function() {
                return !angular.isUndefined($rootScope.totalVoteUser) && $rootScope.totalVoteUser == 15
            }

            //Se checkea en los posibles cambios de estado
            scope.$watch(function() {
                return $state.$current.name
            }, function(newVal, oldVal) {

                if ((newVal == 'main' || newVal == 'main.countdown' || newVal == 'main.editions') && angular.element($window).width() < 768) {

                    angular.element(document).find('header').addClass('headerMblMain');
                } else {
                    angular.element(document).find('header').removeClass('headerMblMain');
                }
            })

        },
        controller: 'NavbarController',
        controllerAs: 'nav'
    }));

function checkStateAndwidth($window, scope) {

    if ((angular.element($window).width()) < 768 && (scope.$state.$current.name == 'main' || scope.$state.$current.name == 'main.countdown' || scope.$state.$current.name == 'main.editions')) {


        angular.element(document).find('header').addClass('headerMblMain');

    } else {


        angular.element(document).find('header').removeClass('headerMblMain');

    }
}
